import ShieldIcon from '../../icons/ShieldIcon';

export default function VehicleDetail({
  title,
  value,
  onEdit,
}: {
  title: string;
  value: string | null;
  onEdit?: () => void;
}) {
  return (
    <div className='grid grid-cols-12'>
      <div className='flex items-end col-span-1 mx-auto w-4   '>
        <ShieldIcon />
      </div>
      <div className='col-span-11 space-x-2 flex flex-row text-p1 lg:text-p2 ml-2 md:ml-0 my-auto'>
        <div>
          {title}&nbsp;<strong className='text-p-blue-dark'>{value}</strong>
        </div>
        {onEdit && (
          <button onClick={onEdit} className='text-accent-blue hover:underline'>
            Edit
          </button>
        )}
      </div>
    </div>
  );
}
