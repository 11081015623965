import { useEffect } from 'react';
import Prompt from '../components/shared/Prompt';

export default function PrivacyPolicy() {
  useEffect(() => {
    document.title = 'CarAnalysis | Privacy Policy';
  }, []);

  return (
    <div>
      <div className='bg-car-background bg-cover bg-center h-48' />
      <div className='flex flex-col mt-10 mb-32 w-5/6 lg:w-4/5 2xl:w-3/4 mx-auto space-y-6'>
        <h2 className='text-sub1 md:text-h2 text-p-blue-dark'>
          <span className='font-bold'>Car Analysis&nbsp;</span>
          <span>Privacy Policy</span>
        </h2>
        <div className='text-p1 lg:text-p2'>
          <p>
            Car Valuations Pty Ltd ABN 658 712 906 and its related bodies
            corporate (Car Valuations, us, we, our) takes your privacy seriously
            and is committed to responsible privacy practices. Please read the
            following privacy policy (Privacy Policy) to understand how how we
            collect, use, disclose, store, handle and protect your personal
            information in accordance with our obligations under the Privacy Act
            1988 (Cth) (Privacy Act). We hope that this will help you make an
            informed decision about sharing personal information with us. As
            well as applying to our interactions with you, this Privacy Policy
            also applies to all information collected through this website at
            valuemycar.com.au (Website).
          </p>
          <p>
            This Privacy Policy sits alongside our Terms of Use (available at{' '}
            <a href='/terms-and-conditions' className='underline'>
              Terms
            </a>
            ).
          </p>
        </div>
        <div className='space-y-1'>
          <h3 className='text-p2 md:text-[18px] font-bold'>
            What is personal information?
          </h3>
          <div className='text-p1 lg:text-p2'>
            <p>
              In this Privacy Policy, ‘personal information’ has the meaning set
              out in the Privacy Act. In general terms, personal information is
              information (whether fact or opinion) about an individual who is
              identified or reasonably identifiable.
            </p>
            <p>
              Some types of personal information are designated as ‘sensitive
              information’, which are subject to additional protection under the
              Privacy Act. For example, these can include information about your
              health.
            </p>
          </div>
        </div>
        <div className='space-y-1'>
          <h3 className='text-p2 md:text-[18px] font-bold'>
            What types of personal information do we collect?
          </h3>
          <div className='text-p1 lg:text-p2'>
            <p>
              The types of personal information we collect about you will depend
              on the purpose for which the personal information is collected.
              This can include:
            </p>
            <ul className='list-disc mx-5 lg:mx-8'>
              <li>
                in the case of Website users – your name, mobile number, email
                address and postcode;
              </li>
              <li>
                in the case of business users – your name, billing or shipping
                address, email address, telephone number(s), and payment
                information (including credit card information or alternative
                payment method account information);
              </li>
              <li>
                if you have requested to receive news about exclusive offers,
                promotions, or events – your name, mailing or street address,
                email address, and telephone number(s);
              </li>
              <li>
                if you have contacted our customer service unit to make a
                complaint, provide feedback, submit an enquiry or request a
                call-back – your name, mailing or street address, email address,
                and telephone number(s);
              </li>
              <li>
                in the case of prospective employees or contractors –
                information contained in your application or résumé, recorded
                during any interview, or obtained through any pre-employment
                checks, and government-issued identifiers such as tax file
                numbers; and
              </li>
              <li>
                in the case of our suppliers and distributors – your name,
                mailing or street address, email address, and telephone
                number(s).
              </li>
            </ul>
            <p>We do not collect sensitive information.</p>
            <p>
              We also collect technical information and general analytics, such
              as web browser type and browsing preferences, Internet service
              provider, referring/exit pages, and date/time stamps, IP address,
              time zone, and geolocation data (if applicable) arising from your
              use of our Website.
            </p>
          </div>
        </div>
        <div className='space-y-1'>
          <h3 className='text-p2 md:text-[18px] font-bold'>
            How do we collect personal information?
          </h3>
          <div className='text-p1 lg:text-p2'>
            <p>
              We collect your personal information directly from you, including
              when you:
            </p>
            <ul className='list-disc mx-5 lg:mx-8'>
              <li>access or use our Website;</li>
              <li>access or use our products or services;</li>
              <li>complete the valuation form available on our Website;</li>
              <li>sign up to our services as a business user;</li>
              <li>
                sign up to receive news and exclusive offers, promotions, or
                events;
              </li>
              <li>
                enter surveys, competitions, promotions or request information
                or material from us;
              </li>
              <li>
                make inquiries about us or our products or services or otherwise
                communicate with us by email, by telephone, in person, via a
                Website or otherwise; and
              </li>
              <li>
                apply to work with us or are engaged by us as a contractor.
              </li>
            </ul>
            <p>
              Where it is reasonable and practicable to do so, we will only
              collect personal information about you from you directly and not
              from third parties. In limited circumstances, we may collect
              personal information about you from publicly available sources
              (such as the Internet) and from third parties (such as mutual
              contacts, or if someone contacts us on your behalf, or from your
              referees during the recruitment process if you apply for a job
              with us). We may also collect personal information through third
              parties such as our users, service providers, through promotional
              and marketing activities, and from publicly available sources of
              information.
            </p>
            <p>
              We also use the following technologies to collect technical
              information and general analytics:
            </p>
            <ul className='list-disc mx-5 lg:mx-8'>
              <li>
                cookies, which are data files that are placed on your device and
                often include an anonymous unique identifier. For more
                information about cookies, and how to disable cookies, visit{' '}
                <a href='http://www.allaboutcookies.org' className='underline'>
                  http://www.allaboutcookies.org
                </a>
                ;
              </li>
              <li>
                log files, which track actions occurring on our Website; and
              </li>
              <li>
                web beacons, tags, and pixels, which are electronic files used
                to record information about how you browse our Website.
              </li>
            </ul>
            <p>
              By using our Website, you are consenting to the use of these
              technologies in accordance with this Privacy Policy.
            </p>
          </div>
        </div>
        <div className='space-y-1'>
          <h3 className='text-p2 md:text-[18px] font-bold'>
            Can you chose not to disclose your personal information?
          </h3>
          <div className='text-p1 lg:text-p2'>
            <p>
              You do not have to identify yourself or provide any personal
              information if you contact us. You can also notify us that you
              wish to deal with us using a pseudonym.
            </p>
            <p>
              However, if we cannot collect personal information about you, we
              may not be able to provide you with the information or assistance
              you require. For example, we will not be able to provide you with
              a valuation certificate or send you information you have requested
              if you have not provided us with a valid email address or
              telephone number.
            </p>
          </div>
        </div>
        <div className='space-y-1'>
          <h3 className='text-p2 md:text-[18px] font-bold'>
            How do we use your personal information?
          </h3>
          <div className='text-p1 lg:text-p2'>
            <p>
              In general, we use your personal information for purposes
              connected with our business.
            </p>
            <p>
              Some of the specific purposes for which we use your personal
              information are as follows:
            </p>
            <ul className='list-disc mx-5 lg:mx-8'>
              <li>
                to provide our products and services to our users (e.g. to
                provide you with a valuation of your car);
              </li>
              <li>
                to share your personal information with our business users to
                facilitate transactions between you and our business users (e.g.
                to enable business users to contact you regarding the sale of
                your car);
              </li>
              <li>
                to enable the proper operation and functionality of our products
                and services;
              </li>
              <li>
                to verify your identity (for example, if you request access to
                the personal information we hold about you);
              </li>
              <li>
                to consider you for a job at Car Valuations (whether as an
                employee or contractor) or other relationships with us;
              </li>
              <li>
                to communicate with you and to address any issues or complaints
                that we or you may have regarding our relationship;
              </li>
              <li>
                for direct marketing purposes (see the “Direct marketing”
                section below); and
              </li>
              <li>
                to contact you regarding the above, including via electronic
                messaging such as SMS and email, by mail, by phone or in any
                other lawful manner.
              </li>
            </ul>
            <p>
              We may also use or disclose your personal information for our
              administrative, marketing (including direct marketing), planning,
              product or service development, quality control, survey and
              research purposes and for other purposes to which you have
              consented, or as otherwise permitted or required by law.
            </p>
            <p>
              We use technical information and general analytics for the purpose
              of gauging visitor traffic, trends and delivering personalised
              content to you while you are using our Website, and to improve our
              Website and our products and services.
            </p>
          </div>
        </div>
        <div className='space-y-1'>
          <h3 className='text-p2 md:text-[18px] font-bold'>
            To whom do we disclose personal information?
          </h3>
          <div className='text-p1 lg:text-p2'>
            <p>
              We may disclose your personal information to third parties in
              connection with the purposes described above (see the “How do we
              use your personal information?” section).
            </p>
            <p>
              This may include disclosing your personal information to the
              following types of third parties:
            </p>
            <ul className='list-disc mx-5 lg:mx-8'>
              <li>our related companies;</li>
              <li>
                any potential third party acquirer of our business or assets,
                and advisors to that third party;
              </li>
              <li>our business users;</li>
              <li>
                our professional advisers (such as lawyers, accountants or
                auditors) and insurers;
              </li>
              <li>
                our employees, contractors and third party service providers who
                assist us in performing our functions and activities e.g.
                payment systems operators and financial institutions, cloud
                service providers, data storage providers, shipping companies,
                telecommunications providers and IT support services providers;
              </li>
              <li>
                organisations authorised by us to conduct promotional, research
                or marketing activities;
              </li>
              <li>
                third parties to whom you have authorised us to disclose your
                information (e.g. referees); and
              </li>
              <li>any other person as required or permitted by law.</li>
            </ul>
            <p>
              If we disclose your personal information to third parties we will
              use reasonable commercial efforts to ensure that such third
              parties only use your personal information as reasonably required
              for the purpose of disclosure and in a manner consistent with
              applicable laws, for example (where commercially practical) by
              including suitable privacy and confidentiality clauses in our
              agreement with a third party service provider to which we disclose
              your personal information.
            </p>
          </div>
        </div>
        <div className='space-y-1 text-p1 lg:text-p2'>
          <h3 className=''>Direct marketing communications</h3>
          <p>
            We and our business users will only send you direct marketing
            communications (either through mail, SMS or email), including any
            news and exclusive offers, promotions, or events, where you have
            consented for us to do so.
          </p>
          <p>
            You may opt-out of receiving direct marketing communications at any
            time by contacting us or our business users by using opt-out
            facilities provided in the direct marketing communications.
          </p>
        </div>
        <div className='space-y-1'>
          <h3 className='text-p2 md:text-[18px] font-bold'>
            Does personal information leave Australia?
          </h3>
          <div className='text-p1 lg:text-p2 space-y-6'>
            <p>
              We store personal information in servers located in Australia. We
              may disclose your personal information to overseas recipients,
              such as to service providers located overseas (including Heap
              Analytics and Google Cloud), in order to provide its products and
              services and to obtain services. It is not practical for us to
              list every country where such overseas recipients may be located,
              however such countries are likely to include the USA and the
              Philippines.
            </p>
            <p>
              Except where an exception applies under the Privacy Act or other
              relevant legislation, we will take commercially reasonable steps
              to ensure that overseas recipients to whom we disclose personal
              information do not breach the Australian Privacy Principles stated
              in the Privacy Act in relation to such information.
            </p>
          </div>
        </div>
        <div className='space-y-1'>
          <h3 className='text-p2 md:text-[18px] font-bold'>
            How do we protect your personal information?
          </h3>
          <div className='text-p1 lg:text-p2'>
            <p>
              We will take reasonable precautions to protect your personal
              information from misuse, loss, or unauthorised access,
              modification or disclosure and we request the same from third
              parties to which we disclose your personal information. However,
              as the Internet is inherently insecure, we cannot guarantee the
              security of transmission of personal information you disclose to
              us online. Accordingly, you transmit your personal information to
              us online at your own risk.
            </p>
            <p>
              Please notify us immediately if you become aware of any breach of
              security.
            </p>
          </div>
        </div>
        <div className='space-y-1'>
          <h3 className='text-p2 md:text-[18px] font-bold'>
            How long do we keep your personal information?
          </h3>
          <div className='text-p1 lg:text-p2 space-y-6'>
            <p>
              Generally, we will retain your personal information for the period
              necessary for the purposes for which your personal information was
              collected (as outlined in this Privacy Policy) unless a longer
              retention period is required by law or if it is reasonably
              necessary for us to comply with our legal obligations, resolve a
              dispute or maintain security.
            </p>
            <p>
              When personal information is no longer required, we will take
              reasonable steps to delete the personal information from our
              systems or de-identify the personal information. We will only
              retain technical information for 90 days from the date of
              collection.
            </p>
          </div>
        </div>
        <div className='space-y-1'>
          <h3 className='text-p2 md:text-[18px] font-bold'>
            How can you access and correct your personal information?
          </h3>
          <div className='text-p1 lg:text-p2 space-y-6'>
            <p>
              You may request access to any personal information we hold about
              you at any time by contacting us. We will provide access to that
              information in accordance with the Privacy Act, subject to any
              exemptions that may apply. We may charge an administration fee in
              limited circumstances, for example if we are required to.
            </p>
            <p>
              If you believe that personal information we hold about you is
              incorrect, incomplete or inaccurate, then you may request us to
              amend it by contacting us at contact@autograb.com.au. Where we
              agree that the information needs to be corrected, we will update
              it. If we do not agree, you can request that we make a record of
              your correction request with the relevant information.
            </p>
            <p>
              You can also ask us to notify any third parties that we provided
              incorrect information to about the correction. We’ll try and help
              where we can – if we can’t, then we’ll let you know.
            </p>
          </div>
        </div>
        <div className='space-y-1'>
          <h3 className='text-p2 md:text-[18px] font-bold'>
            What is the process for complaining about a breach of privacy?
          </h3>
          <div className='text-p1 lg:text-p2 space-y-6'>
            <p>
              If you have any questions, concerns or complaints about our
              collection, use, disclosure or management of your personal
              information, please contact us in writing using the contact
              details below.
            </p>
            <p>
              We are committed to resolving any complaints reasonably and to
              ensuring that we are doing the right thing by you. We will make
              all reasonable inquiries and your complaint will be assessed with
              the aim of resolving any issue in a timely and efficient manner.
            </p>
            <p>
              If you have raised a complaint with us and you are unsatisfied
              with the outcome or have further concerns about the way we handle
              your personal information, under the Privacy Act, you may complain
              to the Information Commissioner at the Office of the Australian
              Information Commissioner, whose contact details are set out below:
            </p>
            <ul>
              <li>Office of the Australian Information Commissioner</li>
              <li>GPO Box 5218 Sydney NSW 2001</li>
              <li>Phone: 1300 363 992</li>
              <li>
                Online:{' '}
                <a href='http://www.oaic.gov.au' className='underline'>
                  www.oaic.gov.au{' '}
                </a>
              </li>
              <li>Email: enquiries@oaic.gov.au</li>
              <li>How to contact us</li>
            </ul>
            <p>
              If you have a query, concern or complaint about the manner in
              which your personal information has been collected or handled by
              us or would like to request access to or correction of the
              personal information we hold about you, please contact us using
              the details provided below:
            </p>
            <ul>
              <li>Car Valuations Pty LtdAttention: Privacy Officer</li>
              <li>Exemptions and inconsistency with law</li>
            </ul>
            <div>
              <p>
                Where local laws allow for an exemption to compliance with
                certain legal obligations (for example, the employee records
                exemption), we may rely on such an exemption.
              </p>
              <p>
                This Privacy Policy will not apply to the extent that it is
                inconsistent with any applicable law.
              </p>
              <p>Changes to this Privacy Policy</p>
            </div>
            <p>
              We may change this Privacy Policy from time to time at our
              discretion. Amendments to this Privacy Policy will be posted on
              our Website. Your continued dealings with us, for example use of
              its websites, products or services, will signify your agreement to
              this Privacy Policy as amended.
            </p>
            <p>Last updated: 01 March 2023</p>
          </div>
        </div>
      </div>
      <Prompt />
    </div>
  );
}
