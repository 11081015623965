import { useEffect } from 'react';
import Accordion from '../components/shared/Accordion';
import ListItem from '../components/shared/ListItem';
import Prompt from '../components/shared/Prompt';

export default function OurServices() {
  useEffect(() => {
    document.title = 'CarAnalysis | Our Services';
  }, []);

  return (
    <div>
      <div className='flex flex-col bg-our-services bg-center bg-cover h-[409px] w-full'>
        <div className='flex flex-col items-center justify-center space-y-4 sm:space-y-8 bg-white opacity-90 w-5/6 lg:w-1/2 py-6 mx-auto mt-32 md:mt-40'>
          <div className='text-p-blue-mid text-h2 sm:text-h1 font-bold'>
            Our Services
          </div>
          <div className='text-center mx-10 text-p-blue-dark font-bold'>
            Whether you're a seasoned car buyer or a first-time purchaser, this
            report can help you make an informed decision about your next
            vehicle.
          </div>
        </div>
      </div>
      <div className='bg-grey-pale flex text-p-blue-dark '>
        <div className='flex flex-col items-center bg-white mx-auto my-16 px-10 py-14 space-y-8 w-5/6 lg:w-4/5'>
          <div className='text-h3 sm:text-h2 lg:text-h1 font-bold'>
            What's included in the report?
          </div>
          <div className='text-p2 font-semibold'>
            For just $16.00 you&apos;ll get a report that includes detailed
            information about the car's valuation, safety, and specifications.
          </div>
          <div className='grid xs:grid-cols-2 lg:grid-cols-3 xs:gap-x-4 lg:gap-x-20 gap-y-5'>
            <ListItem item='Vehicle Specifications' />
            <ListItem item='Odometer rollback check' />
            <ListItem item='PPSR certificate' />
            <ListItem item='Financial encumbrances' />
            <ListItem item='Vehicle market data' />
            <ListItem item='ANCAP safety ratings' />
            <ListItem item='Safety recall information' />
            <ListItem item='Emissions information' />
            <ListItem item='Retails and trade prices' />
            <ListItem item='Write off and theft check' />
          </div>
        </div>
      </div>
      <div className='flex flex-col items-center py-20 text-p-blue-dark space-y-10 shadow-edge-fade'>
        <div className='px-8 sm:px-4 text-sub1 xs:text-h2 lg:text-h1 font-bold'>
          Frequent Questions and Answers
        </div>
        <Accordion customClassProps='w-5/6 lg:w-2/3' />
      </div>
      <Prompt />
    </div>
  );
}
