export function isInvalidValue(value: unknown) {
  if (Array.isArray(value)) {
    return !value.length;
  } else if (typeof value === 'number') {
    return isNaN(value);
  }
  return value === null || value === undefined;
}

export function commaSeparate(x: number | string): string {
  if (isInvalidValue(x)) {
    return '-';
  }

  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
