import { useState } from 'react';

interface AccordionArrayItem {
  q: string;
  a: string;
}

interface AccordionItemProps {
  question: string;
  answer: string;
  setSelected: (value: number | null) => void;
  selected: number | null;
  index: number;
}

export default function Accordion({
  customClassProps,
}: {
  customClassProps: string;
}) {
  const [selected, setSelected] = useState<number | null>(null);
  const QnAdata = [
    {
      q: 'What is included in the report?',
      a: "The CarAnalysis report provides a comprehensive overview of a vehicle's history and current market value. It includes valuation details, safety information, vehicle specifications, potential odometer rollbacks, write-offs, encumbrances, and much more. For a full list of what's included, see the 'How much does it cost and what's included in the report?' section.",
    },
    {
      q: 'How do I generate a CarAnalysis report?',
      a: "Generating a CarAnalysis report is quick and easy. Simply enter the registration number or vehicle identification number (VIN) of the car you're interested in, and answer a few questions about its history and condition. Once you've provided this information, the CarAnalysis report will be generated, providing you with a complete overview of the car's history and current market value. For more information, see the 'CarAnalysis report in just 2 simple steps' section.",
    },
    {
      q: 'How much does the CarAnalysis report cost?',
      a: "The CarAnalysis report costs $16.00 and includes detailed information about the car's valuation, safety, and specifications. For a full list of what's included, see the 'How much does it cost and what's included in the report?' section.",
    },
    {
      q: 'What risks can the CarAnalysis report help me avoid?',
      a: "The CarAnalysis report can help you avoid several risks associated with buying a used car. These risks include purchasing a car with hidden defects or a damaged history, overpaying for a car, buying a vehicle with unknown safety hazards, buying a vehicle with a tampered odometer, and potential hidden financial liabilities. For more information, see the 'Risks CarAnalysis report can help avoid' section.",
    },
    {
      q: 'Is the CarAnalysis report reliable?',
      a: "Yes, the CarAnalysis report provides reliable data based on the most comprehensive and up-to-date car market data set in Australia. Our team constantly monitors the market and updates our reports with the latest information, giving buyers a complete picture of the vehicle's history and current market value. For more information, see the 'Reliable Car Market Data' section.",
    },
    {
      q: 'Can dealers or those in vehicle finance get trade rates?',
      a: 'Yes, dealers and those in vehicle finance can get trade rates by getting in contact with us today.',
    },
  ];

  return (
    <div className={`flex flex-col text-p2 ${customClassProps}`}>
      {QnAdata.map((obj: AccordionArrayItem, i) => (
        <AccordionItem
          question={obj.q}
          answer={obj.a}
          setSelected={setSelected}
          selected={selected}
          index={i}
          key={i}
        />
      ))}
    </div>
  );
}

function AccordionItem({
  question,
  answer,
  setSelected,
  selected,
  index,
}: AccordionItemProps) {
  return (
    <div className={index === 0 ? '' : 'border-t'}>
      <button
        className='flex flex-row justify-between w-full py-6'
        onClick={() => {
          if (index === selected) {
            setSelected(null);
          } else {
            setSelected(index);
          }
        }}
      >
        <span className='text-p2 font-bold text-left'>{question}</span>
        <svg
          data-accordion-icon
          className={`w-6 h-6 shrink-0 ${
            selected === index
              ? 'rotate-180 duration-700'
              : 'rotate-0 duration-700'
          }`}
          fill='currentColor'
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            fillRule='evenodd'
            d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
            clipRule='evenodd'
          ></path>
        </svg>
      </button>
      <div
        className={`overflow-hidden duration-700 ${
          selected === index ? 'max-h-[400px] mb-2' : 'max-h-0'
        }`}
      >
        {answer}
      </div>
    </div>
  );
}
