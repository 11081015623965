export default function HazardIcon() {
  return (
    <svg
      width='36'
      height='31'
      viewBox='0 0 36 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1107_9)'>
        <path
          d='M18.0741 30.9989C13.8332 30.9989 9.59233 31.0035 5.35144 30.9966C4.07668 30.9942 2.91061 30.6399 1.94039 29.7629C0.890921 28.8142 0.292034 27.6048 0.066744 26.2061C-0.161942 24.7889 0.213918 23.5022 0.967904 22.3193C3.62271 18.1566 6.28317 13.9963 8.94023 9.8348C10.5037 7.38822 12.0569 4.93471 13.6328 2.49737C14.5046 1.14829 15.7204 0.291984 17.3065 0.0588663C18.896 -0.174251 20.2817 0.30237 21.4784 1.38602C22.1157 1.9642 22.5425 2.70279 22.9999 3.4183C24.5192 5.79102 26.0328 8.1672 27.5487 10.5422C30.0564 14.4718 32.5742 18.3932 35.0671 22.332C36.7981 25.0671 36.052 28.5742 33.404 30.2672C32.6195 30.7692 31.7534 31 30.8251 31C26.5751 30.9989 22.324 31 18.0741 31V30.9989ZM18.0628 28.67C22.1429 28.67 26.223 28.67 30.302 28.67C30.5002 28.67 30.6983 28.6735 30.8953 28.6573C31.5009 28.6077 32.0591 28.4219 32.5289 28.0168C33.524 27.1593 34.2044 25.3233 33.2161 23.7653C32.4893 22.6205 31.7681 21.4722 31.039 20.3285C28.1782 15.8451 25.3162 11.3616 22.4531 6.88044C21.7433 5.77024 21.0561 4.64389 20.301 3.56486C19.256 2.07037 17.413 1.91111 16.0884 3.15056C15.7023 3.51293 15.4329 3.96647 15.151 4.40847C12.4905 8.57804 9.83234 12.7488 7.17301 16.9183C5.79183 19.0833 4.40952 21.2483 3.02269 23.4098C2.50984 24.2084 2.22115 25.0497 2.41361 26.0238C2.66834 27.3094 3.3227 28.2176 4.6167 28.558C4.98577 28.655 5.3605 28.6688 5.73749 28.6688C9.84592 28.6688 13.9544 28.6688 18.0616 28.6688L18.0628 28.67Z'
          fill='white'
        />
        <path
          d='M18.0255 21.5473C16.8096 21.5438 16.0601 20.8503 15.9469 19.6304C15.7477 17.4735 15.5405 15.3166 15.3424 13.1585C15.2065 11.679 15.0492 10.2007 14.9552 8.71776C14.8839 7.57756 15.1307 6.49622 15.999 5.69762C17.5794 4.24468 20.078 4.88863 20.8897 6.92091C21.1909 7.6745 21.1229 8.44425 21.0539 9.21977C20.969 10.1649 20.8807 11.1101 20.7935 12.0553C20.6667 13.439 20.5376 14.8238 20.412 16.2075C20.3112 17.3154 20.1991 18.4221 20.1176 19.5312C20.0259 20.7845 19.2436 21.5519 18.0244 21.5485L18.0255 21.5473Z'
          fill='#9DC4F8'
        />
        <path
          d='M15.4997 25.2508C15.5031 23.7748 16.6012 22.6588 18.0413 22.668C19.4485 22.6761 20.5613 23.7886 20.5444 25.2358C20.524 26.983 19.2368 27.8301 18.0107 27.8289C16.5808 27.8289 15.4951 26.7153 15.4985 25.2519L15.4997 25.2508Z'
          fill='#9DC4F8'
        />
      </g>
      <defs>
        <clipPath id='clip0_1107_9'>
          <rect width='36' height='31' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
