import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function PageNotFound() {
  const redirect = useNavigate();

  useEffect(() => {
    document.title = 'CarAnalysis | Page Not Found';
  }, []);

  return (
    <div className='min-h-full bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8'>
      <div className='mx-auto max-w-max'>
        <main className='sm:flex'>
          <p className='text-4xl font-bold tracking-tight text-ag-blue sm:text-h1'>
            404
          </p>
          <div className='sm:border-l sm:border-gray-300 sm:pl-12 sm:ml-12 space-y-6'>
            <h1 className='text-4xl mb-4 tracking-tight text-gray-900 sm:text-5xl'>
              Page not found
            </h1>
            <p className='mt-1 text-base text-gray-500'>
              Please check the URL in the address bar and try again.
            </p>
            <div className='flex flex-row justify-start'>
              <button
                className='bg-accent-blue text-white py-1 px-4 rounded-sm'
                onClick={() => redirect('/')}
              >
                Back To Home
              </button>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default PageNotFound;
