export interface QueryPayload {
  rego: string | null;
  vin: string | null;
  state: string;
  vehicleTitle: string;
  ppsrDisabled?: boolean;
}

export default function OrderSummary({
  rego,
  vin,
  state,
  vehicleTitle,
  ppsrDisabled,
}: QueryPayload) {
  const reportPrice = ppsrDisabled ? '$12.00' : '$16.00';

  return (
    <div className='bg-white flex flex-col shadow-order-summary 2xl:max-w-[550px] px-10 py-16'>
      <div className='text-sub1 font-bold py-3'>Order Summary</div>
      {ppsrDisabled && (
        <div className='text-red-500 pb-5'>
          <p className='font-bold'>Important:</p>
          <p>
            PPSR checks won't be included in the Caranalysis report between
            9:00pm and 11:59pm (Canberra time) due to maintenance. We regret the
            inconvenience. If you would like a PPSR, please run the report
            tomorrow.
          </p>
        </div>
      )}
      <div className='space-y-8'>
        <div className='flex flex-row justify-between text-p-blue-dark text-p1 sm:text-p2 font-semibold'>
          <div>1</div>
          <div>X</div>
          <div className='w-8/12 flex flex-col'>
            <div>Instant CarAnalysis Report</div>
            <div className='font-regular'>Vehicle:</div>
            <div className='font-regular'>{vehicleTitle}</div>
            {rego ? (
              <div className='font-regular'>
                Registration / State: {`${rego} / ${state}`}
              </div>
            ) : (
              <div className='font-regular'>VIN: {vin}</div>
            )}
          </div>
          <div className='w-2/12'>{reportPrice}</div>
        </div>
        <div className='w-full bg-black h-0.5' />
        <div className='flex flex-row justify-between text-p1 sm:text-p2'>
          <div className='pt-2'>Total</div>
          <div>
            <div className='text-h2 sm:text-h1 font-semibold'>
              {reportPrice}
            </div>
            <div>including GST</div>
          </div>
        </div>
        <div className='text-p1 sm:text-p2'>
          Please complete the transaction via our secure checkout. Once payment
          is processed, your CarAnalysis Report will be emailed to your
          nominated email address.
        </div>
      </div>
    </div>
  );
}
