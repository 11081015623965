export default function ShieldIcon() {
  return (
    <svg
      width='25'
      height='26'
      viewBox='0 0 25 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.7 0.150568L22.1355 4.1657C23.2397 4.66759 24.0929 5.72157 24.0929 7.02649C24.0427 12.0454 21.985 21.1297 13.3524 25.2954C12.4992 25.6969 11.5456 25.6969 10.6924 25.2954C2.05986 21.1297 0.00210542 12.0454 0.00210542 7.02649C-0.0480838 5.72157 0.805132 4.66759 1.90929 4.1657L11.3449 0.150568C11.5456 0.100378 11.7966 0 12.0475 0C12.2483 0 12.4992 0.100378 12.7 0.150568Z'
        fill='#0660BC'
      />
      <mask
        id='mask0_958_16'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='25'
        height='26'
      >
        <path
          d='M12.7 0.150568L22.1355 4.1657C23.2397 4.66759 24.0929 5.72157 24.0929 7.02649C24.0427 12.0454 21.985 21.1297 13.3524 25.2954C12.4992 25.6969 11.5456 25.6969 10.6924 25.2954C2.05986 21.1297 0.00210542 12.0454 0.00210542 7.02649C-0.0480838 5.72157 0.805132 4.66759 1.90929 4.1657L11.3449 0.150568C11.5456 0.100378 11.7966 0 12.0475 0C12.2483 0 12.4992 0.100378 12.7 0.150568Z'
          fill='#B70708'
        />
      </mask>
      <g mask='url(#mask0_958_16)'>
        <rect width='12.2438' height='26.3015' fill='#1974D1' />
      </g>
      <path
        d='M6.80212 13.6039L11.45 18.1386L15.8148 12.4702L20.1796 6.80176'
        stroke='white'
        strokeWidth='3'
        strokeLinejoin='round'
      />
    </svg>
  );
}
