import ReactSlider from 'react-slider';

interface Props {
  value: number;
  handleChange: (kms: number) => any;
}

export default function OdometerSlider(props: Props) {
  return (
    <div className='p-3 bg-white rounded-sm border border-gray-400'>
      <div className='text-primary-blue-dark py-2'>Enter kilometers</div>
      <div className='flex flex-col w-full text-p1 border border-1 p-2 rounded-md'>
        <div className='mb-1 block text-sm text-primary-blue-dark font-semibold text-fg-300 capitalise'>
          KM's
        </div>
        <div className='mb-5'>
          <ReactSlider
            className='customSlider'
            thumbClassName='w-5 h-5 border border-1 border-slate-400 bg-white rounded-full'
            trackClassName='customSlider-track'
            step={5000}
            min={0}
            max={500000}
            value={props.value}
            onChange={props.handleChange}
          />
        </div>
        <div className='flex flex-row justify-between text-gray-500'>
          <span>0km</span>
          <span className='pl-12'>250,000km</span>
          <span>500,000km</span>
        </div>
        <input
          type='number'
          step={5000}
          max={500000}
          className='border text-gray-900 p-1.5 my-2 h-[40px] w-1/3 rounded-sm'
          value={props.value}
          onChange={e => {
            const inputValue = Number(e.target.value);
            if (inputValue > 500000) {
              props.handleChange(500000);
            } else {
              props.handleChange(inputValue);
            }
          }}
        />
      </div>
    </div>
  );
}
