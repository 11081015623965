export default function Chevron({ strokeColour }: { strokeColour: string }) {
  return (
    <svg
      width='6'
      height='9'
      viewBox='0 0 6 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5 1L1 4.66667L5 8'
        stroke={strokeColour}
        strokeLinecap='round'
      />
    </svg>
  );
}
