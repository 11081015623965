import { useEffect } from 'react';
import Accordion from '../components/shared/Accordion';
import Prompt from '../components/shared/Prompt';

export default function AboutUs() {
  useEffect(() => {
    document.title = 'CarAnalysis | About Us';
  }, []);

  return (
    <div>
      <div className='flex flex-col bg-mobile-about-us sm:bg-about-us bg-cover bg-center h-[470px] w-full'>
        <div className='flex flex-col lg:items-center justify-center space-y-3 md:space-y-6 bg-white opacity-90 w-5/6 mt-40 lg:mb-auto lg:w-1/2 m-auto p-8'>
          <div className='text-p-blue-mid text-h2 sm:text-h1 font-bold'>
            About Us
          </div>
          <div className='lg:text-center lg:w-2/3 text-p-blue-dark font-bold'>
            CarAnalysis.com.au is powered by AutoGrab's automotive intelligence
            engine, which provides comprehensive and up-to-date car market data
            to help buyers make informed decisions about their next vehicle
            purchase.
          </div>
        </div>
      </div>
      <div className='bg-grey-pale'>
        <div className='flex flex-col lg:flex-row w-4/5 mx-auto py-10 lg:py-24 justify-around'>
          <div className='flex flex-col text-p-blue-dark lg:w-3/5 my-auto space-y-6 lg:pl-36 pb-10'>
            <div className='font-bold text-h3 sm:text-h1'>
              Accurate and reliable
            </div>
            <div className='text-p2 font-semibold'>
              With our reliable and accurate reports, you can get a complete
              picture of the car's history and current market value, including
              valuation details, safety information, vehicle specifications,
              potential odometer rollbacks, write-offs, encumbrances, and much
              more.
            </div>
            <div className='text-p2 space-y-4'>
              Our team constantly monitors the market and updates our reports
              with the latest information, ensuring that you have the most
              reliable data available. Don't risk buying a lemon - get the
              CarAnalysis report today and make an informed decision about your
              next vehicle purchase.
            </div>
          </div>
          <div className='mx-auto -mb-44 xs:-mb-56 lg:order-first lg:mb-5 w-2/3 sm:w-2/5 2xl:w-1/3'>
            <img
              src={require('../images/business-people-badge.png')}
              alt='business-people-badge'
            />
          </div>
        </div>
      </div>
      <div className='flex flex-col items-center pt-56 lg:pt-20 pb-16 text-p-blue-dark space-y-10 shadow-edge-fade'>
        <div className='px-8 sm:px-4 text-sub1 xs:text-h2 lg:text-h1 font-bold'>
          Frequent Questions and Answers
        </div>
        <Accordion customClassProps='w-5/6 lg:w-2/3' />
      </div>
      <Prompt />
    </div>
  );
}
