import ReactDOM from 'react-dom';
import Helmet from 'react-helmet';

export const GTMConfig = () => {
  const enableGTM = process.env.REACT_APP_ENABLE_GTM === 'true';
  const gtmId = process.env.REACT_APP_GTM_ID;

  if (!enableGTM || !gtmId) {
    return null;
  }

  return (
    <>
      <Helmet>
        <script>
          {`
          (function(w,d,s,l,i){
            w[l]=w[l]||[];
            w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});
            var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
            j.async=true;
            j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
            f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtmId}');
        `}
        </script>
      </Helmet>
      {ReactDOM.createPortal(
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
            height='0'
            width='0'
            style={{ display: 'none', visibility: 'hidden' }}
          ></iframe>
        </noscript>,
        document.body
      )}
    </>
  );
};
