import { useNavigate } from 'react-router-dom';
import { commaSeparate } from '../../util/format';
import { QueryPayload } from './OrderSummary';
import VehicleDetail from './VehicleDetail';

interface ConfirmVehicleProps extends QueryPayload {
  displayStepTwo: string;
  setDisplayStepTwo: (value: string) => void;
  displayStepThree: string;
  setDisplayStepThree: (value: string) => void;
  odometer?: string;
}

export default function ConfirmVehicle({
  displayStepTwo,
  setDisplayStepTwo,
  displayStepThree,
  setDisplayStepThree,
  rego,
  vin,
  state,
  vehicleTitle,
  odometer,
}: ConfirmVehicleProps) {
  const redirect = useNavigate();

  const toggleSteps = () => {
    setDisplayStepTwo(Boolean(displayStepTwo) ? '' : 'show-step-2');

    if (Boolean(displayStepThree)) {
      setDisplayStepThree('');
    }
  };

  return (
    <div className='flex flex-col justify-between space-y-5 my-5'>
      <div className='text-sub1 lg:text-h2 font-semibold lg:font-regular'>
        1. Confirm Vehicle
      </div>
      <div className='text-p1 lg:text-p2 font-p-blue-dark'>
        A <strong>CarAnalysis report</strong> is available for your vehicle
      </div>
      <div className='bg-grey-pale border border-grey-dark'>
        <div className='flex flex-col my-4 mx-2 lg:mx-0 space-y-2'>
          {rego ? (
            <VehicleDetail
              title='REGO / State: '
              value={`${rego} / ${state}`}
              onEdit={() => {
                redirect('/', {
                  state: 'scroll',
                });
              }}
            />
          ) : (
            <VehicleDetail title='VIN: ' value={vin} />
          )}
          <VehicleDetail title='Vehicle: ' value={vehicleTitle} />
          {odometer && (
            <VehicleDetail
              title='Kilometers: '
              value={commaSeparate(odometer)}
            />
          )}
        </div>
      </div>
      <div className='flex items-center p-2'>
        <input
          id='checkbox-1'
          type='checkbox'
          value={displayStepTwo}
          className='w-4 h-4 text-blue-600 rounded'
          onClick={toggleSteps}
        />
        <label htmlFor='checkbox-1' className='ml-2 text-sm font-medium'>
          Yes, this is the correct vehicle
        </label>
      </div>
    </div>
  );
}
