import { FormEvent, useState } from 'react';
import { useSubmitContactFormMutation } from 'src/graphql/generated';
import Alert from '../shared/Alert';
import InputField from '../shared/InputField';

export default function ContactForm() {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [enquiry, setEnquiry] = useState<string>('');
  const [formAlert, setFormAlert] = useState<string | null>(null);

  const [submitContactForm, { data: contactFormData }] =
    useSubmitContactFormMutation();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormAlert(null);

    if (!name || !email || !number || !subject || !enquiry) {
      setFormAlert('Please fill out all fields');
      return;
    }

    try {
      await submitContactForm({
        variables: {
          input: {
            email,
            enquiry,
            name,
            number,
            subject,
          },
        },
      });

      setFormAlert('success');
    } catch (err: any) {
      setFormAlert(
        err.message || 'Error submitting enquiry - please try again'
      );
    }
  };

  return (
    <div className=''>
      <form onSubmit={handleSubmit}>
        <div className='flex flex-col w-full space-y-3 sm:space-y-6'>
          <div className='flex flex-col sm:flex-row justify-between sm:space-x-10 md:space-x-24 lg:space-x-12 space-y-3 sm:space-y-0'>
            <InputField
              labelName='Your Name'
              value={name}
              setValue={setName}
              inputId='name'
              labelStyle='font-regular ml-1'
            />
            <InputField
              labelName='Your Email'
              value={email}
              setValue={setEmail}
              inputId='email'
              labelStyle='font-regular ml-1'
            />
          </div>
          <div className='flex flex-col sm:flex-row justify-between sm:space-x-10 md:space-x-24 lg:space-x-12 space-y-3 sm:space-y-0'>
            <InputField
              labelName='Your Number'
              value={number}
              setValue={setNumber}
              inputId='number'
              labelStyle='font-regular ml-1'
            />
            <InputField
              labelName='Enquiry Subject'
              value={subject}
              setValue={setSubject}
              inputId='subject'
              labelStyle='font-regular ml-1'
            />
          </div>
          <div className='flex flex-col space-y-2 w-full'>
            <label htmlFor='enquiry' className='font-regular ml-1'>
              Your Enquiry
            </label>
            <textarea
              id='enquiry'
              rows={4}
              value={enquiry}
              onChange={({ target }) => setEnquiry(target.value)}
              className={`outline-grey-dark outline outline-1 rounded-sm focus:outline-[#41517C] focus:outline-1.5 ${
                enquiry.length && 'bg-[#FAFAFA]'
              }`}
            />
          </div>
          {formAlert && formAlert !== 'success' && (
            <Alert text={formAlert} type='error' />
          )}
          {formAlert === 'success' && (
            <Alert text='Your form has been submitted!' type='success' />
          )}
          <div className='flex flex-row justify-end'>
            <button
              type='submit'
              className='py-3 my-2 px-4 w-full sm:w-1/5 text-white bg-accent-blue hover:bg-blue-800 font-bold rounded-sm'
            >
              SUBMIT
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
