export default function ShieldIconOutline() {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 25 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 12.8021L10.6479 17.3369L15.0127 11.6684L19.3775 6'
        stroke='#042782'
        strokeWidth='2'
        strokeLinejoin='round'
      />
      <path
        d='M1.00211 7.02649V7.00726L1.00137 6.98805C0.970521 6.18607 1.49068 5.45849 2.3129 5.08073L11.671 1.09855C11.7134 1.08676 11.7528 1.07512 11.7847 1.06568L11.7877 1.0648C11.8468 1.04732 11.8897 1.03464 11.9319 1.02354C11.9849 1.00957 12.0165 1.00388 12.033 1.00157C12.0487 1.00487 12.072 1.01052 12.1061 1.01998C12.142 1.02996 12.1772 1.04054 12.2229 1.0543C12.2328 1.05727 12.2432 1.0604 12.2542 1.06369C12.2883 1.07393 12.3295 1.0862 12.3737 1.09852L21.7319 5.08073C22.5478 5.45562 23.0909 6.1915 23.0929 7.02192C23.0429 11.8816 21.0439 20.4704 12.9227 24.3924C12.3415 24.6645 11.7033 24.6645 11.1222 24.3924C2.99792 20.4689 1.00211 11.8755 1.00211 7.02649Z'
        stroke='#042782'
        strokeWidth='2'
      />
    </svg>
  );
}
