export default function LockIcon() {
  return (
    <svg
      width='13'
      height='15'
      viewBox='0 0 13 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.625 6V4.25C2.625 2.33594 4.18359 0.75 6.125 0.75C8.03906 0.75 9.625 2.33594 9.625 4.25V6H10.5C11.457 6 12.25 6.79297 12.25 7.75V13C12.25 13.9844 11.457 14.75 10.5 14.75H1.75C0.765625 14.75 0 13.9844 0 13V7.75C0 6.79297 0.765625 6 1.75 6H2.625ZM3.9375 6H8.3125V4.25C8.3125 3.04688 7.32812 2.0625 6.125 2.0625C4.89453 2.0625 3.9375 3.04688 3.9375 4.25V6ZM1.3125 13C1.3125 13.2461 1.50391 13.4375 1.75 13.4375H10.5C10.7188 13.4375 10.9375 13.2461 10.9375 13V7.75C10.9375 7.53125 10.7188 7.3125 10.5 7.3125H1.75C1.50391 7.3125 1.3125 7.53125 1.3125 7.75V13Z'
        fill='black'
      />
    </svg>
  );
}
