import { Facet, VehicleDetails } from '../../graphql/generated';
import { FacetOptions, FacetSearchParams } from './VehicleSearchForm';
interface FacetSelectProps {
  inputId: string;
  value?: string;
  selectItems: Facet[] | null;
  facetSearchFields: FacetSearchParams;
  setFacetSearchFields: (value: FacetSearchParams) => void;
  setSearchResults: (value: VehicleDetails[]) => void;
}

export default function FacetSelect({
  inputId,
  value,
  selectItems,
  facetSearchFields,
  setFacetSearchFields,
  setSearchResults,
}: FacetSelectProps) {
  const updateFacetSearch = (value: string, id: FacetOptions) => {
    setSearchResults([]);

    const { make, model, year } = facetSearchFields;

    switch (id) {
      case 'make':
        setFacetSearchFields({
          facet: 'model',
          make: value,
          model: '',
          year: '',
          badge: '',
        });
        break;
      case 'model':
        setFacetSearchFields({
          facet: 'year',
          make,
          model: value,
          year: '',
          badge: '',
        });
        break;
      case 'year':
        setFacetSearchFields({
          facet: 'badge',
          make,
          model,
          year: value,
          badge: '',
        });
        break;
      case 'badge':
        setFacetSearchFields({
          facet: 'badge',
          make,
          model,
          year,
          badge: value,
        });
        break;
    }
  };

  const facets = ['make', 'model', 'year', 'badge'];
  const disabled =
    facets.indexOf(inputId) > facets.indexOf(facetSearchFields.facet);

  return (
    <div className='w-full'>
      <select
        id={inputId}
        className='border border-ea-gray-blue h-10 w-full'
        value={value}
        onChange={({ target }) => {
          updateFacetSearch(target.value, target.id as FacetOptions);
        }}
        disabled={disabled}
      >
        <option value=''>
          Select {`${inputId.charAt(0).toUpperCase()}${inputId.substring(1)}`}
        </option>
        {selectItems?.map((r, i) => (
          <option value={r.value} key={i}>
            {r.value}
          </option>
        ))}
      </select>
    </div>
  );
}
