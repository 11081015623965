import { Link, useLocation } from 'react-router-dom';
import Chevron from '../../icons/Chevron';
import LockIcon from '../../icons/LockIcon';

export default function Success() {
  const { state: reportLink } = useLocation();

  return (
    <div className='lg:h-screen'>
      <nav className='border-b-2 border-grey-dark flex flex-row justify-between'>
        <Link to='/' className='flex items-center my-auto p-5'>
          <img
            src={require('../../icons/logo.png')}
            className='h-6 lg:h-8 mr-3'
            alt='car-analysis-logo'
          />
        </Link>
        <div className='flex flex-row justify-center space-x-2 p-6'>
          <div className='my-auto pb-0.5'>
            <LockIcon />
          </div>
          <div className='my-auto text-p1 md:text-p2'>Secure Checkout</div>
        </div>
      </nav>
      <div className='flex flex-col lg:flex-row m-auto justify-between sm:p-4 lg:p-20 lg:space-x-10 items-center mb-8 lg:mb-0 lg:mr-24'>
        <div className='lg:w-1/2 space-y-5 p-10'>
          <div className='text-primary-blue-light text-h2 sm:text-h1 font-bold'>
            Great news,
          </div>
          <div className='text-p-blue-mid sm:text-h2 font-bold'>
            Your CarAnalysis report has been successfully generated and sent to
            your email address.
          </div>
          <div className='text-p-blue-dark text-[12px] font-semibold'>
            Please check your inbox and spam folder to ensure that you have
            received the report. Alternatively you can click
            <a
              href={reportLink}
              target='_blank'
              rel='noopenner noreferrer'
              className='text-accent-blue font-semibold ml-1 underline hover:text-blue-800'
            >
              here
            </a>
            &nbsp;to download.
          </div>
          <div className='text-p-blue-dark text-[12px]'>
            If you encounter any issues, please contact us at
            <p className='ml-1 underline inline'>contact@caranalysis.com.au</p>
            &nbsp;and we will be happy to assist you.
          </div>
          <div className='text-p-blue-dark text-[12px] font-semibold'>
            Dealership or enterprise customer? Get trade rates on future
            CarAnalysis reports by getting in contact with us today
          </div>
          <Link to='/' className='flex flex-row'>
            <div className='my-auto mr-1 pb-0.5'>
              <Chevron strokeColour='#0A0A2D' />
            </div>
            <div className='text-p-blue-dark text-[12px] font-semibold'>
              Return home
            </div>
          </Link>
        </div>
        <div className='w-1/2 md:w-1/3 p-82'>
          <img
            src={require('../../images/success.png')}
            alt='payment-success'
            className='m-auto'
          />
        </div>
      </div>
    </div>
  );
}
