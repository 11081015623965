import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useScrollDirection } from 'react-use-scroll-direction';
import { useScrollPosition } from 'src/hooks/scroll';

interface NavBarProps {
  setIsScrollToForm: (value: boolean) => void;
}

export default function NavBar({ setIsScrollToForm }: NavBarProps) {
  const [hideMobileNav, setHideMobileNav] = useState<boolean>(true);
  const scrollPosition = useScrollPosition();
  const { isScrolling, isScrollingUp, isScrollingDown } = useScrollDirection();

  const { pathname } = useLocation();
  const redirect = useNavigate();

  const scrollToForm = () => {
    if (pathname !== '/') {
      redirect('/', {
        state: 'scroll',
      });
    }

    setIsScrollToForm(true);
  };

  const redirectAndToggleNav = (path: string) => {
    redirect(path);
    setHideMobileNav(true);
  };

  const selected = 'text-white bg-blue-700 md:bg-transparent md:text-gray-500';
  const unselected =
    'text-p-blue-dark md:hover:bg-transparent md:hover:text-blue-700';

  const toggleStickyNavBar =
    scrollPosition > 200 && (!isScrolling || isScrollingDown);

  return (
    <>
      {window.innerWidth > 768 && (
        <nav
          className={`bg-white z-30 top-0 w-full inset-y-0 transform ${
            toggleStickyNavBar
              ? 'sticky -translate-y-0'
              : 'relative -translate-y-20'
          } duration-1000 ease-in-out`}
        >
          <div className='absolute z-10 w-full bg-white flex flex-wrap items-center justify-between mx-auto p-4 shadow-lg'>
            <Link to='/' className='flex items-center'>
              <img
                src={require('../../icons/logo.png')}
                className='h-5 xs:h-6 lg:h-8 mr-3'
                alt='car-analysis-logo'
              />
            </Link>
            <div className='flex md:order-1'>
              <button
                type='button'
                className='md:hidden text-white text-[11px] xs:text-p1 md:text-p2 bg-blue-700 hover:bg-blue-800 font-medium px-2 sm:px-4 sm:py-2 text-center mr-3 md:mr-0'
                onClick={scrollToForm}
              >
                GET REPORT
              </button>
              <button
                data-collapse-toggle='navbar-sticky'
                type='button'
                className='inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden'
                aria-controls='navbar-sticky'
                aria-expanded='false'
                onClick={() => setHideMobileNav(!hideMobileNav)}
              >
                <span className='sr-only'>Open main menu</span>
                <svg
                  className='w-6 h-6'
                  aria-hidden='true'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'
                    clipRule='evenodd'
                  ></path>
                </svg>
              </button>
            </div>
            <div
              className={`${
                hideMobileNav && 'hidden'
              } items-center justify-between w-full md:flex md:w-auto md:order-1`}
            >
              <ul className='flex flex-col p-4 md:p-0 mt-4 text-p1 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 lg:space-x-16 2xl:space-x-20 md:my-auto md:border-0 md:bg-white'>
                <li>
                  <button
                    className={`block py-2 pl-3 pr-4 md:mt-2 rounded md:bg-transparent md:p-0 ${
                      pathname === '/' ? selected : unselected
                    }`}
                    onClick={() => redirectAndToggleNav('/')}
                  >
                    Home
                  </button>
                </li>
                <li>
                  <button
                    className={`block py-2 pl-3 pr-4 md:mt-2 rounded md:p-0 ${
                      pathname === '/our-services' ? selected : unselected
                    }`}
                    onClick={() => redirectAndToggleNav('/our-services')}
                  >
                    Our Services
                  </button>
                </li>
                <li>
                  <button
                    className={`block py-2 pl-3 pr-4 md:mt-2 rounded md:p-0 ${
                      pathname === '/contact-us' ? selected : unselected
                    }`}
                    onClick={() => redirectAndToggleNav('/contact-us')}
                  >
                    Contact Us
                  </button>
                </li>
                <li>
                  <button
                    className={`block py-2 pl-3 pr-4 md:mt-2 rounded md:p-0 ${
                      pathname === '/about-us' ? selected : unselected
                    }`}
                    onClick={() => redirectAndToggleNav('/about-us')}
                  >
                    About Us
                  </button>
                </li>
                <li>
                  <button
                    className='hidden md:block text-white bg-accent-blue hover:bg-blue-800 font-medium text-sm px-4 py-2 text-center md:mr-0'
                    onClick={scrollToForm}
                  >
                    GET REPORT
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}
      <nav className='bg-white z-20 top-0 left-0 border-b border-gray-200'>
        <div className='absolute z-10 w-full lg:w-11/12 bg-white flex flex-wrap items-center justify-between mx-auto p-4 lg:left-0 lg:right-0 lg:top-5 shadow-lg'>
          <Link to='/' className='flex items-center'>
            <img
              src={require('../../icons/logo.png')}
              className='h-5 xs:h-6 lg:h-8 mr-3'
              alt='car-analysis-logo'
            />
          </Link>
          <div className='flex md:order-1'>
            <button
              type='button'
              className='md:hidden text-white text-[11px] xs:text-p1 md:text-p2 bg-blue-700 hover:bg-blue-800 font-medium px-2 sm:px-4 sm:py-2 text-center mr-3 md:mr-0'
              onClick={scrollToForm}
            >
              GET REPORT
            </button>
            <button
              data-collapse-toggle='navbar-sticky'
              type='button'
              className='inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden'
              aria-controls='navbar-sticky'
              aria-expanded='false'
              onClick={() => setHideMobileNav(!hideMobileNav)}
            >
              <span className='sr-only'>Open main menu</span>
              <svg
                className='w-6 h-6'
                aria-hidden='true'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'
                  clipRule='evenodd'
                ></path>
              </svg>
            </button>
          </div>
          <div
            className={`${
              hideMobileNav && 'hidden'
            } items-center justify-between w-full md:flex md:w-auto md:order-1`}
          >
            <ul className='flex flex-col p-4 md:p-0 mt-4 text-p1 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 lg:space-x-16 2xl:space-x-20 md:my-auto md:border-0 md:bg-white'>
              <li>
                <button
                  className={`block py-2 pl-3 pr-4 md:mt-2 rounded md:bg-transparent md:p-0 ${
                    pathname === '/' ? selected : unselected
                  }`}
                  onClick={() => redirectAndToggleNav('/')}
                >
                  Home
                </button>
              </li>
              <li>
                <button
                  className={`block py-2 pl-3 pr-4 md:mt-2 rounded md:p-0 ${
                    pathname === '/our-services' ? selected : unselected
                  }`}
                  onClick={() => redirectAndToggleNav('/our-services')}
                >
                  Our Services
                </button>
              </li>
              <li>
                <button
                  className={`block py-2 pl-3 pr-4 md:mt-2 rounded md:p-0 ${
                    pathname === '/contact-us' ? selected : unselected
                  }`}
                  onClick={() => redirectAndToggleNav('/contact-us')}
                >
                  Contact Us
                </button>
              </li>
              <li>
                <button
                  className={`block py-2 pl-3 pr-4 md:mt-2 rounded md:p-0 ${
                    pathname === '/about-us' ? selected : unselected
                  }`}
                  onClick={() => redirectAndToggleNav('/about-us')}
                >
                  About Us
                </button>
              </li>
              <li>
                <button
                  className='hidden md:block text-white bg-accent-blue hover:bg-blue-800 font-medium text-sm px-4 py-2 text-center md:mr-0'
                  onClick={scrollToForm}
                >
                  GET REPORT
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
