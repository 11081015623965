import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { GTMConfig } from './GTMConfig';
import Footer from './components/shared/Footer';
import NavBar from './components/shared/NavBar';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Home from './pages/Home';
import OurServices from './pages/OurServices';
import PageNotFound from './pages/PageNotFound';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import VinInfo from './pages/VinInfo';
import PaymentPage from './pages/payment/PaymentPage';
import Success from './pages/payment/Success';

export default function App() {
  const [isScrollToForm, setIsScrollToForm] = useState<boolean>(false);
  const { pathname, state } = useLocation();

  const navAndFooterUrlPaths = [
    '/',
    '/our-services',
    '/about-us',
    '/contact-us',
    '/privacy-policy',
    '/terms-and-conditions',
  ];

  useEffect(() => {
    if (state !== 'scroll') {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <>
      <GTMConfig />
      <div className='font-titillium'>
        {navAndFooterUrlPaths.includes(pathname) && (
          <NavBar setIsScrollToForm={setIsScrollToForm} />
        )}
        <Routes>
          <Route
            path='/'
            element={
              <Home
                isScrollToForm={isScrollToForm}
                setIsScrollToForm={setIsScrollToForm}
              />
            }
          />
          <Route path='our-services' element={<OurServices />} />
          <Route path='about-us' element={<AboutUs />} />
          <Route path='contact-us' element={<ContactUs />} />
          <Route path='privacy-policy' element={<PrivacyPolicy />} />
          <Route path='terms-and-conditions' element={<TermsAndConditions />} />
          <Route path='payment' element={<PaymentPage />} />
          <Route path='success' element={<Success />} />
          <Route path='vin-info' element={<VinInfo />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
        {navAndFooterUrlPaths.includes(pathname) && <Footer />}
      </div>
    </>
  );
}
