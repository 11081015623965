export default function DownloadIcon() {
  return (
    <svg
      width='11'
      height='13'
      viewBox='0 0 11 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.875 12.375C0.382812 12.375 0 11.9922 0 11.5C0 11.0352 0.382812 10.625 0.875 10.625H9.625C10.0898 10.625 10.5 11.0352 10.5 11.5C10.5 11.9922 10.0898 12.375 9.625 12.375H0.875ZM5.85156 8.62891C5.52344 8.98438 4.94922 8.98438 4.62109 8.62891L1.12109 5.12891C0.765625 4.80078 0.765625 4.22656 1.12109 3.89844C1.44922 3.54297 2.02344 3.54297 2.35156 3.89844L4.375 5.89453V1C4.375 0.535156 4.75781 0.125 5.25 0.125C5.71484 0.125 6.125 0.535156 6.125 1V5.89453L8.12109 3.89844C8.44922 3.54297 9.02344 3.54297 9.35156 3.89844C9.70703 4.22656 9.70703 4.80078 9.35156 5.12891L5.85156 8.62891Z'
        fill='white'
      />
    </svg>
  );
}
