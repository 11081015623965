import cx from 'classnames';

type WarningType = 'error' | 'success';

interface AlertProps {
  text: string;
  type: WarningType;
}

export default function Alert({ text = '', type }: AlertProps) {
  return (
    <div className='mt-2 md:mt-0'>
      <div
        className={cx(`rounded-sm p-3 w-full flex justify-center`, {
          'bg-red-200': type === 'error',
          'bg-green-200': type === 'success',
        })}
      >
        <div className='flex'>
          {type === 'error' && <ErrorIcon />}
          {type === 'success' && <SuccessIcon />}
          <div className='my-auto'>
            <span
              className={cx(`rounded-sm p-3 w-full text-p1`, {
                'text-red-800': type === 'error',
                'text-green-800': type === 'success',
              })}
            >
              {text}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

function ErrorIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth={1.5}
      stroke='red'
      className='w-6 h-6 stroke-red-800'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z'
      />
    </svg>
  );
}

function SuccessIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth={1.5}
      stroke='currentColor'
      className='w-6 h-6 stroke-green-800'
    >
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
      />
    </svg>
  );
}
