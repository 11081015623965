import { Link } from 'react-router-dom';

interface SearchErrorProps {
  error: string | null;
  setSearchError: (value: string | null) => void;
  setDisplayManualSearch: (value: boolean) => void;
}

export default function SearchError({
  error,
  setSearchError,
  setDisplayManualSearch,
}: SearchErrorProps) {
  return (
    <div className='flex h-20 justify-center p-4'>
      {error === 'lookup-error' && (
        <p className='my-auto text-center text-white text-p2 font-semibold'>
          Sorry, we couldn't find your vehicle. Please check your details and
          try again.
        </p>
      )}
      {error === 'valuation-error' && (
        <p className='my-auto text-center text-white text-p2 font-semibold'>
          Sorry, we could not generate your report. Please try again or
          <Link
            to='/contact-us'
            className='text-primary-blue-light hover:text-blue-400'
          >
            &nbsp;contact our team&nbsp;
          </Link>
          for assistance
        </p>
      )}
      {error !== 'lookup-error' && error !== 'valuation-error' && (
        <p className='my-auto text-center text-white text-p2 font-semibold'>
          {error}
        </p>
      )}
    </div>
  );
}
