import { MouseEvent } from 'react';
import { VehicleDetails } from '../../graphql/generated';
import Spinner from '../shared/Spinner';
import Vehicle from './Vehicle';

interface SingleResultDisplayProps {
  searchResults: VehicleDetails[];
  valuationLoading: boolean;
  handleGetMyReport: (value: MouseEvent<HTMLButtonElement>) => void;
}
interface MultipleResultsDisplayProps extends SingleResultDisplayProps {
  selectedVehicleIndex: number;
  setSelectedVehicleIndex: (value: number) => void;
  setOdometer: (value: string) => void;
}

interface SearchResultsProps extends MultipleResultsDisplayProps {
  vehicleLookupLoading: boolean;
  setOdometer: (value: string) => void;
  facetVehicleSearchLoading: boolean;
}

export default function VehicleSearchResults({
  searchResults,
  valuationLoading,
  handleGetMyReport,
  selectedVehicleIndex,
  setSelectedVehicleIndex,
  vehicleLookupLoading,
  facetVehicleSearchLoading,
  setOdometer,
}: SearchResultsProps) {
  if (vehicleLookupLoading || facetVehicleSearchLoading) {
    return null;
  }

  return (
    <div>
      <p className='text-white my-2 font-semibold'>Confirm your vehicle:</p>
      {searchResults.length > 1 ? (
        <MultipleResultsDisplay
          setOdometer={setOdometer}
          searchResults={searchResults}
          valuationLoading={valuationLoading}
          handleGetMyReport={handleGetMyReport}
          selectedVehicleIndex={selectedVehicleIndex}
          setSelectedVehicleIndex={setSelectedVehicleIndex}
        />
      ) : (
        <SingleResultDisplay
          setOdometer={setOdometer}
          searchResults={searchResults}
          valuationLoading={valuationLoading}
          handleGetMyReport={handleGetMyReport}
          selectedVehicleIndex={selectedVehicleIndex}
          setSelectedVehicleIndex={setSelectedVehicleIndex}
        />
      )}
    </div>
  );
}

function MultipleResultsDisplay({
  searchResults,
  valuationLoading,
  handleGetMyReport,
  selectedVehicleIndex,
  setSelectedVehicleIndex,
  setOdometer,
}: MultipleResultsDisplayProps) {
  return (
    <>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-3'>
        {searchResults.map((v: VehicleDetails, i) => (
          <Vehicle
            key={i}
            index={i}
            compositeTitle={v.composite_title}
            vehicleTitle={v.vehicle_title}
            year={v.year}
            selectedVehicleIndex={selectedVehicleIndex}
            setSelectedVehicleIndex={setSelectedVehicleIndex}
            isMultipleResults={searchResults.length > 1}
          />
        ))}
      </div>
      <div className='flex justify-end'>
        <button
          type='submit'
          className='py-2 px-4 text-white bg-accent-blue hover:bg-blue-800 text-p1 font-bold w-full lg:w-1/4 mt-10'
          onClick={handleGetMyReport}
        >
          {valuationLoading ? (
            <Spinner customStyling='fill-blue-600 mx-auto' />
          ) : (
            <span>GET MY REPORT</span>
          )}
        </button>
      </div>
    </>
  );
}

function SingleResultDisplay({
  searchResults,
  valuationLoading,
  handleGetMyReport,
  selectedVehicleIndex,
  setSelectedVehicleIndex,
  setOdometer,
}: MultipleResultsDisplayProps) {
  return (
    <div className='flex flex-col lg:flex-row justify-between space-y-3 lg:space-y-0 lg:space-x-4'>
      <div className='lg:w-3/4'>
        {searchResults.map((v: VehicleDetails, i) => (
          <Vehicle
            key={i}
            index={i}
            compositeTitle={v.composite_title}
            vehicleTitle={v.vehicle_title}
            year={v.year}
            selectedVehicleIndex={selectedVehicleIndex}
            setSelectedVehicleIndex={setSelectedVehicleIndex}
            isMultipleResults={searchResults.length > 1}
          />
        ))}
      </div>
      <div className='flex flex-col justify-end lg:w-1/4 py-1 lg:p-0'>
        <div className='flex flex-row justify-end'>
          <button
            type='submit'
            className='py-2 px-4 h-10 text-white bg-accent-blue hover:bg-blue-800 text-p1 font-bold w-full'
            onClick={handleGetMyReport}
          >
            {valuationLoading ? (
              <Spinner customStyling='fill-blue-600 mx-auto' />
            ) : (
              <span>GET MY REPORT</span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
