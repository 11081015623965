import { useLocation } from 'react-router-dom';
import ShieldIcon from '../../icons/ShieldIcon';
import ShieldIconOutline from '../../icons/ShieldIconOutline';

export default function ListItem({ item }: { item: string }) {
  const { pathname } = useLocation();

  return (
    <span className='flex flex-row justify-start'>
      <div className='my-auto pr-2'>
        {pathname !== '/' ? <ShieldIcon /> : <ShieldIconOutline />}
      </div>
      <div className='my-auto w-3/4 lg:w-full text-[12px] sm:text-p1'>
        {item}
      </div>
    </span>
  );
}
