import { useNavigate } from 'react-router-dom';

export default function Prompt() {
  const redirect = useNavigate();

  return (
    <div className='bg-laptop bg-cover bg-center'>
      <div className='w-full h-full flex flex-col py-8 sm:py-5 lg:py-10 justify-center items-center backdrop-brightness-50 space-y-4 2xl:space-y-6'>
        <div className='text-white text-center px-3 sm:px-0 text-sub1 sm:text-h2 lg:text-h1 font-bold my-auto'>
          Want a CarAnalysis Report?
        </div>
        <div className='flex flex-row justify-end'>
          <button
            type='submit'
            className='py-2 px-4 text-white bg-accent-blue hover:bg-blue-800 text-p1 font-bold w-full'
            onClick={() => {
              redirect('/', {
                state: 'scroll',
              });
            }}
          >
            GET STARTED NOW
          </button>
        </div>
      </div>
    </div>
  );
}
