import cx from 'classnames';

interface InputFieldProps {
  labelName: string;
  value: string;
  setValue: (value: string) => void;
  inputId: string;
  labelStyle: string;
  error?: string;
  onBlur?: () => void;
}

export default function InputField({
  labelName,
  value,
  setValue,
  inputId,
  labelStyle,
  error,
  onBlur,
}: InputFieldProps) {
  return (
    <div className='flex flex-col space-y-1 w-full'>
      <label htmlFor={inputId} className={labelStyle}>
        {labelName}
      </label>
      <input
        type='text'
        id={inputId}
        value={value}
        onChange={({ target }) => setValue(target.value)}
        onBlur={onBlur}
        className={cx(
          value.length && 'bg-[#FAFAFA]',
          error ? 'outline-red-500' : 'outline-grey-dark',
          'outline outline-1 rounded-sm text-p2 sm:text-p1 h-8 py-1 pl-2 focus:outline-[#41517C] focus:outline-1.5'
        )}
      />
      {error && <div className='text-red-500 text-p1'>{error}</div>}
    </div>
  );
}
