import { useEffect, useState } from 'react';
import validator from 'validator';
import InputField from '../shared/InputField';

interface EnterYourDetailsProps {
  displayStepThree: string;
  setDisplayStepThree: (value: string) => void;
  firstName: string;
  setFirstName: (value: string) => void;
  lastName: string;
  setLastName: (value: string) => void;
  email: string;
  setEmail: (value: string) => void;
  phoneNumber: string;
  setPhoneNumber: (value: string) => void;
  postcode: string;
  setPostcode: (value: string) => void;
  reason: string;
  setReason: (value: string) => void;
}

export default function EnterYourDetails({
  displayStepThree,
  setDisplayStepThree,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phoneNumber,
  setPhoneNumber,
  postcode,
  setPostcode,
  reason,
  setReason,
}: EnterYourDetailsProps) {
  const [emailError, setEmailError] = useState<string>();
  const [phoneNumberError, setPhoneNumberError] = useState<string>();

  const reportReasons: string[] = [
    'Buying a car',
    'Selling a car',
    'Other reason',
  ];

  const validateInput = (input: string, field: string) => {
    if (field === 'email') {
      if (validator.isEmail(input)) {
        setEmailError(undefined);
      } else {
        setEmailError('Enter a valid email address');
      }
    } else if (field === 'phone-number') {
      if (validator.isMobilePhone(input)) {
        setPhoneNumberError(undefined);
      } else {
        setPhoneNumberError('Enter a valid phone number');
      }
    }
  };

  const allTextFieldsComplete = Boolean(
    firstName && lastName && email && phoneNumber && postcode
  );

  useEffect(() => {
    if (allTextFieldsComplete) {
      validateInput(email, 'email');
      validateInput(phoneNumber, 'phone-number');
    }
  }, [allTextFieldsComplete]);

  const checkboxEnabled =
    allTextFieldsComplete &&
    Boolean(reason) &&
    !(emailError || phoneNumberError);

  return (
    <div className='flex flex-col justify-between space-y-5 my-5'>
      <div className='text-p1 lg:text-p2 font-p-blue-dark'>
        All fields required.
      </div>
      <div className='flex flex-col space-y-2 xs:space-y-3'>
        <div className='flex flex-col xs:flex-row justify-between xs:space-x-6 space-y-2 xs:space-y-0'>
          <InputField
            labelName='Your Name'
            value={firstName}
            setValue={setFirstName}
            inputId='name'
            labelStyle='text-p1 font-semibold text-p-blue-dark'
          />
          <InputField
            labelName='Your Surname'
            value={lastName}
            setValue={setLastName}
            inputId='surname'
            labelStyle='text-p1 font-semibold text-p-blue-dark'
          />
        </div>
        <div className='flex flex-col xs:flex-row justify-between xs:space-x-6 space-y-2 xs:space-y-0'>
          <InputField
            labelName='Your Email'
            value={email}
            setValue={setEmail}
            inputId='email'
            labelStyle='text-p1 font-semibold text-p-blue-dark'
            error={emailError}
            onBlur={() => validateInput(email, 'email')}
          />
          <InputField
            labelName='Your Number'
            value={phoneNumber}
            setValue={setPhoneNumber}
            inputId='number'
            labelStyle='text-p1 font-semibold text-p-blue-dark'
            error={phoneNumberError}
            onBlur={() => validateInput(phoneNumber, 'phone-number')}
          />
        </div>
        <div className='flex flex-col xs:flex-row justify-between xs:space-x-6 space-y-2 xs:space-y-0'>
          <InputField
            labelName='Your Postcode'
            value={postcode}
            setValue={setPostcode}
            inputId='postcode'
            labelStyle='text-p1 font-semibold text-p-blue-dark'
          />
          <div className='w-full flex flex-col space-y-1'>
            <label
              htmlFor='reason'
              className='text-p1 font-semibold text-p-blue-dark'
            >
              Reason for Report
            </label>
            <select
              id='reason'
              value={reason}
              className={`outline outline-grey-dark outline-1 rounded-sm text-p2 sm:text-p1 w-full h-8 pl-1 border-r-[4px] border-r-white ${
                reason === '' ? 'text-grey-dark' : 'text-gray-900'
              }`}
              onChange={({ target }) => setReason(target.value)}
            >
              <option disabled={true} value='' className='text-grey-pale'>
                Select
              </option>
              {reportReasons.map((r: string) => (
                <option value={r} key={r}>
                  {r}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className='flex items-center px-2 py-6'>
        <input
          id='checkbox-1'
          type='checkbox'
          value={displayStepThree}
          className='w-7 h-4 text-blue-600 rounded'
          onClick={() =>
            setDisplayStepThree(Boolean(displayStepThree) ? '' : 'show-step-3')
          }
          disabled={!checkboxEnabled}
        />
        <label htmlFor='checkbox-2' className='ml-2 text-[12px] font-medium'>
          I acknowledge and consent to the collection, use and disclosure of my
          personal information for the purposes set out in Privacy Collection
          Notice
        </label>
      </div>
    </div>
  );
}
