interface VehicleResultProps {
  compositeTitle: string;
  vehicleTitle: string;
  year: string;
  selectedVehicleIndex: number;
  setSelectedVehicleIndex: (value: number) => void;
  index: number;
  isMultipleResults: boolean;
}

export default function Vehicle({
  compositeTitle,
  vehicleTitle,
  year,
  selectedVehicleIndex,
  setSelectedVehicleIndex,
  index,
  isMultipleResults,
}: VehicleResultProps) {
  return (
    <div
      className={`flex flex-col border border-white rounded-md text-white text-p1 p-2 
        ${
          index === selectedVehicleIndex
            ? `${
                isMultipleResults && 'bg-primary-blue-light bg-opacity-50'
              } hover:cursor-default`
            : 'hover:bg-primary-blue-light hover:bg-opacity-30 duration-500 hover:cursor-pointer'
        }`}
      onClick={() => setSelectedVehicleIndex(index)}
    >
      <div className='font-semibold pb-2 text-p1'>{vehicleTitle}</div>
      <div className='font-semibold'>{`Released ${year}`}</div>
      <div>{compositeTitle}</div>
    </div>
  );
}
