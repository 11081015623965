import { useEffect } from 'react';
import ContactForm from '../components/forms/ContactForm';
import Accordion from '../components/shared/Accordion';

export default function ContactUs() {
  useEffect(() => {
    document.title = 'CarAnalysis | Contact Us';
  }, []);

  return (
    <div>
      <div className='relative flex flex-col bg-mobile-contact-us md:bg-contact-us bg-cover bg-center h-[663px] w-full'>
        <div className='m-auto w-5/6 md:w-4/5 mb-10 md:mb-auto'>
          <div className='bg-white opacity-90 md:bg-transparent p-4 sm:p-6 flex flex-col space-y-3 md:mt-40 xl:mt-0 md:space-y-6 md:w-1/2'>
            <div className='text-h2 sm:text-h1 text-p-blue-dark font-bold pr-10'>
              Contact Us
            </div>
            <div className='text-p-blue-dark text-p2 font-regular md:pr-10 xl:pr-28'>
              If you have any questions or concerns about the CarAnalysis
              report, please don't hesitate to get in touch with us. Our
              customer service team is available to assist you with any issues
              you may have, and we're always happy to answer any questions you
              might have about our services.
            </div>
          </div>
        </div>
      </div>
      <div className='lg:mb-80 2xl:mb-96'>
        <div className='relative flex flex-col w-4/5 mx-auto justify-between'>
          <div className='flex flex-col my-20 text-p-blue-dark space-y-10 w-full lg:w-1/2'>
            <div className='text-sub1 xs:text-h2 lg:text-h1 font-bold'>
              Frequent Questions and Answers
            </div>
            <Accordion customClassProps='' />
          </div>
          <div className='lg:hidden w-3/5 mx-auto pb-20'>
            <img
              src={require('../images/report-mask-3.png')}
              alt='report-mask'
            />
          </div>
          <div className='hidden lg:block absolute md:right-10 w-1/3 md:top-40 2xl:top-20'>
            <img
              src={require('../images/report-mask-3.png')}
              alt='report-mask'
            />
          </div>
        </div>
      </div>
      <div className='relative bg-get-in-touch bg-cover bg-center h-[250px] lg:h-[663px] w-full'>
        <ContactInfoAndForm customClassProps='hidden lg:flex absolute -top-1/2 w-4/5 left-0 right-0' />
      </div>
      <ContactInfoAndForm customClassProps='lg:hidden' />
    </div>
  );
}

function ContactInfoAndForm({
  customClassProps,
}: {
  customClassProps: string;
}) {
  return (
    <div
      className={`${customClassProps} bg-white flex flex-col lg:flex-row mx-auto shadow-even`}
    >
      <div className='bg-grey-pale flex flex-col p-8 sm:p-12 lg:p-16 space-y-10 lg:w-1/3'>
        <div className='mx-auto w-4/5 sm:w-1/2 lg:max-xl:w-3/4 xl:w-4/5'>
          <img src={require('../icons/logo.png')} alt='caranalysis-logo' />
        </div>
        <div className='text-p-blue-dark text-p2 font-semibold'>
          Thank you for choosing CarAnalysis, and we look forward to assisting
          you with all your vehicle purchasing needs.
        </div>
        <div className='font-regular text-p2'>
          You can reach us by submitting a contact form. Our customer service
          team is available from 10am to 4pm AEST, Monday to Friday.
        </div>
        <div className='font-regular text-p2'>
          If you're a dealer or in vehicle finance and would like to inquire
          about trade rates, please get in touch with us via the contact form.
        </div>
      </div>
      <div className='flex flex-col my-14 px-8 sm:px-12 lg:px-20 2xl:px-32 space-y-8 lg:w-2/3'>
        <div className='text-p-blue-mid text-h1 font-bold'>Get in Touch</div>
        <div className='text-p-blue-dark sm:text-p1'>
          We value your feedback, so if you have any suggestions for how we can
          improve our services or if you have any feedback about your experience
          with CarAnalysis, please let us know.
        </div>
        <ContactForm />
      </div>
    </div>
  );
}
