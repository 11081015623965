import { useEffect, useState } from 'react';
import {
  VehicleDetails,
  useCascadingFacetSearchQuery,
  useMakeFacetSearchQuery,
} from '../../graphql/generated';
import FacetSelect from './FacetSelect';
import { FacetSearchParams } from './VehicleSearchForm';

interface FacetFormProps {
  facetSearchFields: FacetSearchParams;
  setFacetSearchFields: (value: FacetSearchParams) => void;
  setSearchResults: (value: VehicleDetails[]) => void;
  setSearchError: (value: string | null) => void;
}

export default function FacetsForm({
  facetSearchFields,
  setFacetSearchFields,
  setSearchResults,
  setSearchError,
}: FacetFormProps) {
  const [makeData, setMakeData] = useState<any>([]);
  const [modelData, setModelData] = useState<any>([]);
  const [yearData, setYearData] = useState<any>([]);
  const [badgeData, setBadgeData] = useState<any>([]);

  const { data: makeFacetData } = useMakeFacetSearchQuery();

  const { data: cascadingFacetData } = useCascadingFacetSearchQuery({
    variables: {
      input: facetSearchFields,
    },
  });

  useEffect(() => {
    if (!makeFacetData?.make_facet_search) {
      return;
    }
    setMakeData(makeFacetData?.make_facet_search.makeResults);
  }, [makeFacetData?.make_facet_search]);

  useEffect(() => {
    setSearchError(null);

    if (!cascadingFacetData?.cascading_facet_search) {
      return;
    }

    switch (facetSearchFields.facet) {
      case 'model':
        setModelData(cascadingFacetData.cascading_facet_search);
        setYearData([]);
        setBadgeData([]);
        break;
      case 'year':
        setYearData(cascadingFacetData.cascading_facet_search);
        setBadgeData([]);
        break;
      case 'badge':
        if (badgeData.length === 0) {
          setBadgeData(cascadingFacetData.cascading_facet_search);
        }
        break;
    }
  }, [cascadingFacetData?.cascading_facet_search]);

  return (
    <div className='w-full'>
      <form className='mb-4 py-6 md:flex md:flex-col md:justify-between space-y-3'>
        <div className='flex flex-col md:flex-row md:space-x-3 space-y-3 md:space-y-0'>
          <FacetSelect
            inputId='make'
            value={facetSearchFields.make}
            selectItems={makeData}
            facetSearchFields={facetSearchFields}
            setFacetSearchFields={setFacetSearchFields}
            setSearchResults={setSearchResults}
          />
          <FacetSelect
            inputId='model'
            value={facetSearchFields.model}
            selectItems={modelData}
            facetSearchFields={facetSearchFields}
            setFacetSearchFields={setFacetSearchFields}
            setSearchResults={setSearchResults}
          />
        </div>
        <div className='flex flex-col md:flex-row md:space-x-3 space-y-3 md:space-y-0'>
          <FacetSelect
            inputId='year'
            value={facetSearchFields.year}
            selectItems={yearData}
            facetSearchFields={facetSearchFields}
            setFacetSearchFields={setFacetSearchFields}
            setSearchResults={setSearchResults}
          />
          <FacetSelect
            inputId='badge'
            value={facetSearchFields.badge}
            selectItems={badgeData}
            facetSearchFields={facetSearchFields}
            setFacetSearchFields={setFacetSearchFields}
            setSearchResults={setSearchResults}
          />
        </div>
      </form>
    </div>
  );
}
