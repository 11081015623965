import { useNavigate } from 'react-router-dom';

export default function VinInfo() {
  const redirect = useNavigate();

  return (
    <div>
      <div className='bg-car-background bg-cover bg-center h-48' />
      <div className='flex flex-col mt-12 mb-32 w-5/6 lg:w-4/5 2xl:w-3/4 mx-auto space-y-6'>
        <h2 className='text-sub1 md:text-h2 text-p-blue-dark'>
          How can I locate my VIN?
        </h2>
        <div className='space-y-3'>
          <p>
            The Vehicle Identification Number (VIN) is a unique 17-character
            code that identifies your vehicle. It's essential for tracking
            things like recalls, registrations, warranties, claims, and theft.
            You can find your VIN in several spots both inside and outside your
            vehicle:
          </p>
          <ol className='list-decimal mx-5 lg:mx-8'>
            <li>
              On the driver&apos;s side of the dashboard, near where it meets
              the windshield.
            </li>
            <li>
              On the door post, where the door latches when closed, also on the
              driver&apos;s side.
            </li>
            <li>Under the bonnet, at the front of the engine block.</li>
            <li>
              Behind the driver&apos;s side tire, at the front end of the frame.
            </li>
            <li>
              Inside the driver&apos;s side door jamb, where the door connects
              to the vehicle&apos;s body.
            </li>
          </ol>
          <p>
            If you&apos;re still having trouble locating it, you can also check
            your vehicle&apos;s registration papers, the owner&apos;s manual, or
            contact the dealership or manufacturer with your vehicle&apos;s make
            and model for assistance.
          </p>
        </div>
        <div>
          <button
            type='submit'
            className='inline-block py-2 px-4 text-white bg-accent-blue hover:bg-blue-800 text-p1 font-bold'
            onClick={() => {
              redirect('/', {
                state: 'scroll',
              });
            }}
          >
            GET YOUR CARANALYSIS REPORT
          </button>
        </div>
      </div>
    </div>
  );
}
