import { format, isWithinInterval, parseISO } from 'date-fns';
import { fromZonedTime, toZonedTime } from 'date-fns-tz';

export const isPpsrServiceDisabled = (): boolean => {
  const now = new Date();
  const timeZone = 'Australia/Sydney';

  const localTime = toZonedTime(now, timeZone);

  if (!(localTime.getDay() === 3)) {
    return false;
  }

  const startTimeString = `${format(now, 'yyyy-MM-dd')}T21:00:00`;
  const endTimeString = `${format(now, 'yyyy-MM-dd')}T23:59:59`;
  const startTime = fromZonedTime(parseISO(startTimeString), timeZone);
  const endTime = fromZonedTime(parseISO(endTimeString), timeZone);

  const isWithinTimeInterval = isWithinInterval(localTime, {
    start: startTime,
    end: endTime,
  });

  return isWithinTimeInterval;
};
