import { FormEvent } from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../shared/Spinner';

interface RegoFormProps {
  regoOrVin: string;
  setRegoOrVin: (value: string) => void;
  setRegoOrVinLookupError: (value: string | null) => void;
  state: string;
  setState: (value: string) => void;
  vehicleLoading: boolean;
  handleSubmit: (value: FormEvent<HTMLFormElement>) => void;
  searchDisabled: boolean;
}

export default function RegoForm({
  regoOrVin,
  setRegoOrVin,
  setRegoOrVinLookupError,
  state,
  setState,
  vehicleLoading,
  handleSubmit,
  searchDisabled,
}: RegoFormProps) {
  const regions: string[] = [
    'NSW',
    'ACT',
    'NT',
    'QLD',
    'SA',
    'TAS',
    'VIC',
    'WA',
  ];

  return (
    <div className=''>
      <form
        className='flex flex-col lg:flex-row justify-between space-y-3 lg:space-y-0 lg:space-x-2'
        onSubmit={handleSubmit}
      >
        <input
          type='text'
          id='rego-input'
          value={regoOrVin}
          onChange={({ target }) => {
            setRegoOrVinLookupError(null);
            setRegoOrVin(target.value);
          }}
          placeholder='Enter Rego or VIN'
          className='border text-gray-900 p-1.5 h-[40px] lg:w-3/5'
        />
        <div className='flex flex-row justify-between lg:w-2/5'>
          <select
            id='region-select'
            value={state}
            className='text-gray-900 text-sm px-1.5 w-1/3 h-[40px] border-r-white rounded-none'
            onChange={({ target }) => {
              setRegoOrVinLookupError(null);
              setState(target.value);
            }}
          >
            <option disabled={true} value=''>
              State
            </option>
            {regions.map((r: string) => (
              <option value={r} key={r}>
                {r}
              </option>
            ))}
          </select>
          <button
            type='submit'
            className={`py-2 px-4 ml-4 text-white bg-accent-blue hover:bg-blue-800 text-p2 font-bold w-2/3 ${
              searchDisabled && 'opacity-50 hover:bg-accent-blue'
            }`}
            disabled={searchDisabled}
          >
            {vehicleLoading ? (
              <Spinner customStyling='fill-blue-600 mx-auto' />
            ) : (
              <span>SEARCH</span>
            )}
          </button>
        </div>
      </form>
      <Link
        to='/vin-info'
        className='text-white text-p1 mt-2 underline'
        rel='noopenner noreferrer'
      >
        Where is my VIN?
      </Link>
    </div>
  );
}
