import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DownloadIcon from 'src/icons/DownloadIcon';
import VehicleSearchForm from '../components/forms/VehicleSearchForm';
import ListItem from '../components/shared/ListItem';
import CarIcon from '../icons/CarIcon';
import Chevron from '../icons/Chevron';
import HazardIcon from '../icons/HazardIcon';
import OdometerIcon from '../icons/OdometerIcon';
import PaymentIcon from '../icons/PaymentIcon';

interface HomeProps {
  isScrollToForm: boolean;
  setIsScrollToForm: (value: boolean) => void;
}

export default function Home({ isScrollToForm, setIsScrollToForm }: HomeProps) {
  useEffect(() => {
    document.title = 'CarAnalysis | Home';
  }, []);

  const { state } = useLocation();

  useEffect(() => {
    const formElement = document.getElementById('vehicle-search-form');

    if (formElement && isScrollToForm) {
      formElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }

    setIsScrollToForm(false);
  }, [isScrollToForm]);

  useEffect(() => {
    const formElement = document.getElementById('vehicle-search-form');

    if (formElement && state === 'scroll') {
      formElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }

    window.history.replaceState({}, document.title);
  }, []);

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'pageview',
        page: {
          url: window.location.href,
          pathname: window.location.pathname,
        },
      });
    }
  }, []);

  return (
    <div>
      <div className='flex bg-home-mobile md:bg-home-tablet xl:bg-home-laptop 2xl:bg-home-desktop bg-center bg-cover w-full'>
        <div className='mx-auto my-24 sm:my-28 w-5/6 xl:h-[450px]'>
          <div className='flex flex-col space-y-6 md:w-1/2 lg:w-2/5 2xl:w-1/3 my-6'>
            <div className='text-h2 text-p-blue-dark font-bold xl:pr-10'>
              Get a Vehicle Report online &amp; instantly get the full story
              before making any buying or selling decisions
            </div>
            <div className='font-p2 xl:pr-12'>
              A CarAnalysis report provides a comprehensive overview of a
              vehicle's history and where it currently sits in the market,
              allowing you to make informed decisions before purchasing or
              selling.
            </div>
          </div>
          <Link
            className='inline-block bg-blue-500 py-2 px-3 text-white mb-4'
            to='/files/sample-report.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            <div className='flex flex-row items-center space-x-2'>
              <DownloadIcon />
              <p className='font-semibold'>DOWNLOAD SAMPLE REPORT</p>
            </div>
          </Link>
          <div className='px-4 lg:pb-0 ml-0 md:w-7/12 lg:w-1/2 2xl:w-1/3'>
            <div className='grid grid-cols-2 gap-y-4 md:max-xl:gap-x-10 py-5 text-p1 font-bold text-p-blue-dark'>
              <ListItem item='Valuation details' />
              <ListItem item='Stolen Check' />
              <ListItem item='Vehicle specifications' />
              <ListItem item='Potential Odometer Rollbacks' />
              <ListItem item='Write-offs' />
              <ListItem item='Money Owing on the Vehicle' />
            </div>
          </div>
        </div>
      </div>
      <div className='relative flex flex-col'>
        <div
          className='bg-primary-blue-dark flex flex-col items-center justify-between -mt-20 z-20 w-11/12 xs:w-5/6 md:w-2/3 2xl:w-2/3 3xl:w-1/2 mx-auto py-6 shadow-md'
          id='vehicle-search-form'
        >
          <div className='w-5/6 space-y-6 z-10'>
            <div className='text-white font-semibold text-p2 sm:text-sub2 text-center'>
              Enter vehicle details to generate report
            </div>
            <VehicleSearchForm />
          </div>
        </div>
        <div className='flex flex-col lg:flex-row w-5/6 lg:4/5 mx-auto pt-10 sm:pt-16 lg:pt-28 pb-10 lg:pb-28 justify-between space-y-4 lg:space-y-0 lg:space-x-20'>
          <div className='text-p-blue-mid text-h3 sm:text-h1 font-bold'>
            Who is the CarAnalysis report for?
          </div>
          <div className='lg:w-4/5 space-y-4'>
            <div className='font-bold text-sub2 sm:text-sub1'>
              The CarAnalysis report is designed for anyone who is looking to
              buy or sell a used car.
            </div>
            <div className='text-p2 space-y-4'>
              <p>
                Whether you're a seasoned car buyer or a first-time purchaser,
                or even if you're someone looking to sell their car, this report
                can help you make an informed decision about your next vehicle.
              </p>
              <p>
                By providing a complete picture of the car's history, current
                market value, condition, and value, the CarAnalysis report is an
                essential tool for anyone who wants to ensure that they're
                getting a good deal on a quality vehicle or for sellers who want
                to provide potential buyers with a transparent view of their
                car's condition and value.
              </p>
            </div>
          </div>
        </div>
        <div className='flex mx-auto -mb-28 sm:-mb-48 w-3/5 sm:w-2/5 lg:hidden'>
          <img
            src={require('../images/report-mask-1.png')}
            alt='car-analysis-logo'
          />
        </div>
      </div>
      <div className='bg-grey-pale'>
        <div className='flex flex-row w-5/6 lg:w-4/5 mx-auto py-24 justify-between lg:space-x-32'>
          <div className='hidden lg:block md:w-1/3 md:m-auto'>
            <img
              src={require('../images/report-mask-1.png')}
              alt='car-analysis-logo'
            />
          </div>
          <div className='flex flex-col w-full pt-16 sm:pt-20 md:pt-32 lg:pt-0 lg:w-2/3 my-auto space-y-6'>
            <div className='font-bold text-h3 sm:text-h1 text-p-blue-mid'>
              Why generate a CarAnalysis report?
            </div>
            <div className='text-sub2 sm:text-sub1 text-p-blue-dark font-semibold'>
              Don't risk buying a lemon - get the CarAnalysis report today
            </div>
            <div className='text-p2 space-y-4'>
              <p>
                Generating a CarAnalysis report is essential for anyone looking
                to buy a used car. Without a comprehensive overview of the
                vehicle's history and current market value, it's difficult to
                make an informed decision about whether or not to purchase.
              </p>
              <p>
                The report provides valuable information about the condition of
                the car, including any potential odometer rollbacks or
                write-offs, which could affect its safety and resale value.
              </p>
              <p>
                By getting a CarAnalysis report, buyers can avoid the risk of
                purchasing a lemon and ensure that they're getting a quality
                vehicle that's worth the asking price.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col lg:items-center justify-center w-4/5 mx-auto space-y-6 py-12 md:py-24'>
        <div className='font-bold text-h3 sm:text-h1 text-p-blue-mid'>
          Risks CarAnalysis report can help avoid
        </div>
        <div className='font-semibold text-sub2 text-p-blue-dark pb-4'>
          Buyers risk making a bad investment without a comprehensive overview
          of the vehicle's history and current market value. Risks can include:
        </div>
        <div className='flex flex-col lg:flex-row'>
          <div className='bg-primary-blue-light grid grid-cols-10 min-h-[130px] lg:w-1/4 p-5 space-x-3'>
            <div className='my-auto sm:ml-4 lg:mx-0 lg:my-1 col-span-2'>
              <CarIcon />
            </div>
            <div className='text-white text-p1 font-semibold col-span-8 my-auto lg:my-0'>
              Purchasing a car with hidden defects or a damage history, leading
              to costly repairs down the road.
            </div>
          </div>
          <div className='bg-primary-blue-mid grid grid-cols-10 min-h-[130px] lg:w-1/4 p-5 space-x-3'>
            <div className='my-auto sm:ml-5 lg:mx-0 lg:my-1 col-span-2'>
              <PaymentIcon />
            </div>
            <div className='text-white text-p1 font-semibold col-span-8 my-auto lg:my-0'>
              Paying more than the car is actually worth, either because they're
              unaware of its true value or because the seller is intentionally
              overpricing it.
            </div>
          </div>
          <div className='bg-primary-blue-dark grid grid-cols-10 min-h-[130px] lg:w-1/4 p-5 space-x-3'>
            <div className='my-auto sm:ml-5 lg:mx-0 lg:my-1 col-span-2'>
              <HazardIcon />
            </div>
            <div className='text-white text-p1 font-semibold col-span-8 my-auto lg:my-0'>
              <p>Buying a vehicle with unknown safety hazards.</p>
              <p>Potential for hidden financial liabilities.</p>
            </div>
          </div>
          <div className='bg-p-blue-mid grid grid-cols-10 min-h-[130px] lg:w-1/4 p-5 space-x-3'>
            <div className='my-auto sm:ml-5 lg:mx-0 lg:my-0 col-span-2'>
              <OdometerIcon />
            </div>
            <div className='text-white text-p1 font-semibold col-span-8 my-auto lg:my-0'>
              Buying a vehicle with a tampered odometer that’s subject to
              fraudulent activity
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col bg-mobile-car-background lg:bg-car-background bg-center bg-cover bg-fixed h-[197px] lg:h-[396px] w-full' />
      <div className='bg-grey-pale flex flex-col items-center pb-8 sm:pb-20 lg:pb-0'>
        <div className='flex flex-row bg-white w-4/5 mx-auto -mt-20 lg:-mt-72 border border-grey-pale shadow-lg'>
          <div className='lg:w-1/2 m-6 sm:m-10 space-y-4 md:space-y-8'>
            <div className='text-p-blue-mid font-bold text-h3 sm:text-h2 lg:text-h1'>
              How much does it cost and what's included in the report?
            </div>
            <div className='text-p-blue-dark font-semibold text-p2'>
              For just $16.00 you’ll get a report that includes detailed
              information about the car's valuation, safety, and specifications.
            </div>
            <div className='flex flex-col md:flex-row justify-start md:max-lg:justify-around pl-5 md:pl-0 md:space-x-12 py-5 text-p2 md:text-p1 font-regular text-p-blue-dark'>
              <div className='mb-4 md:mb-0 space-y-4'>
                <ListItem item='Vehicle specifications' />
                <ListItem item='Odometer rollback check' />
                <ListItem item='PPSR certificate' />
                <ListItem item='Financial encumbrances' />
                <ListItem item='Vehicle market data' />
              </div>
              <div className='space-y-4'>
                <ListItem item='ANCAP safety ratings' />
                <ListItem item='Safety recall information' />
                <ListItem item='Emissions information' />
                <ListItem item='Retail and trade prices' />
                <ListItem item='Write off and theft check' />
              </div>
            </div>
            <div className='text-p1 2xl:text-p2'>
              <div className='mr-1'>
                Are you a dealer or in vehicle finance?
                <Link
                  to='/contact-us'
                  className='text-accent-blue font-semibold ml-1 hover:underline'
                >
                  Get trade rates by getting in contact with us today
                  <div className='inline-block rotate-180 ml-1'>
                    <Chevron strokeColour='#3B6BE7' />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className='hidden lg:block w-1/3 2xl:w-1/4 m-auto'>
            <img
              src={require('../images/report-mask-2.png')}
              alt='car-analysis-logo'
            />
          </div>
        </div>
        <div className='w-4/5 flex flex-col xl:flex-row justify-between mx-auto my-16 space-y-8 xl:space-y-0'>
          <div className='text-p-blue-mid font-bold text-h3 sm:text-h1 pr-16'>
            CarAnalysis report in just 2 simple steps
          </div>
          <div className='flex flex-col space-y-10'>
            <div className='flex flex-col md:flex-row bg-white p-10 space-x-0 md:space-x-5 space-y-5 md:space-y-0 border border-grey-pale shadow-lg'>
              <div className='flex xs:max-md:flex-row flex-col md:w-1/3 justify-start md:justify-end'>
                <div className='mr-5'>
                  <img
                    src={require('../icons/step-1.png')}
                    alt='report-step-1'
                  />
                  <div className='flex text-p-blue-mid font-bold text-h1 -mt-10'>
                    Step 1
                  </div>
                </div>
                <div className='text-p-blue-dark text-[18px] font-semibold my-auto md:pt-3'>
                  Enter the rego or VIN
                </div>
              </div>
              <div className='text-p-blue-dark flex flex-col justify-around space-y-3 text-p1'>
                <p>
                  To generate a CarAnalysis report, simply enter the
                  registration number or VIN of the car you're interested in.
                </p>
                <p>
                  This will allow us to access the vehicle's history and provide
                  you with a comprehensive overview.
                </p>
              </div>
            </div>
            <div className='flex flex-col md:flex-row bg-white p-10 space-x-0 md:space-x-5 space-y-5 md:space-y-0 border border-grey-pale shadow-lg'>
              <div className='flex xs:max-md:flex-row flex-col md:w-1/3 justify-start md:justify-end'>
                <div className='mr-5'>
                  <img
                    src={require('../icons/step-2.png')}
                    alt='report-step-2'
                  />
                  <div className='flex text-p-blue-mid font-bold text-h1 -mt-10'>
                    Step 2
                  </div>
                </div>
                <div className='text-p-blue-dark text-[18px] font-semibold my-auto md:pt-3'>
                  Answer a few questions
                </div>
              </div>
              <div className='text-p-blue-dark flex flex-col justify-around space-y-3 text-p1'>
                <p>
                  Once you've entered the rego or VIN, you'll be asked a few
                  questions about the car's history and condition.
                </p>
                <p>
                  This information will help us generate a more accurate report,
                  providing you with a complete picture of the vehicle and its
                  value.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='shadow-edge-fade'>
        <div className='flex flex-col lg:flex-row w-5/6 lg:w-4/5 mx-auto justify-around py-16'>
          <div className='mx-auto -mt-28 sm:-mt-32 lg:mt-5 block w-2/3 lg:w-1/2 pb-8'>
            <img
              src={require('../images/badge-layers.png')}
              alt='badge-layers'
            />
          </div>
          <div className='flex flex-col w-full lg:w-1/2 space-y-8 lg:pl-16 my-auto'>
            <div className='text-p-blue-mid text-h3 sm:text-h1 font-bold'>
              Reliable Car Market Data
            </div>
            <div className='text-p-blue-dark text-p2 font-semibold'>
              CarAnalysis provides reliable data based on the most comprehensive
              and up-to-date car market data set in Australia.
            </div>
            <div className='text-p-blue-dark text-p2'>
              Our team constantly monitors the market and updates our reports
              with the latest information, giving buyers a complete picture of
              the vehicle's history and current market value.
            </div>
            <a
              href='/about-us'
              className='text-accent-blue font-semibold hover:underline'
            >
              Learn more about us
              <div className='inline-block rotate-180 ml-1'>
                <Chevron strokeColour='#3B6BE7' />
              </div>
            </a>
          </div>
        </div>
      </div>
      {/* <div className='w-4/5 mx-auto my-16'>
        <Gallery />
      </div> */}
    </div>
  );
}
